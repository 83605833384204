.link-plain {
  text-decoration: none;
}

.applyselfLabel {
  .MuiTypography-root {
    font-size: 14px !important;
  }
}
.au-form {
  // width: 600px;
 background: #fff;
 border-radius: 10px;
 padding:10px;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
//   p {
//     color: #fff;
//   }
// }
// .form-checkbox {
//   span {
//     color: #fff !important;
//   }
// }
.form-field {
  input,
  div {
    // color: #fff;
    // background-color: transparent;
  }
  svg {
    // color: #fff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    // -webkit-text-fill-color: #fff !important;
  }
}
.form-field-btn {
  position: relative;
  button {
    position: absolute;
    top: 16px;
    right: 0px;
  }
  @media screen and (max-width: 650px) {
    button {
      position: relative;
      top: 12px;
      right: 0px;
    }
  }
}
